import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";

import Meta from "../components/meta";
import Layout from "../components/layout";

// Slider setting
import Slider from "react-slick";
import VideoSrc from "../movies/tochi.mp4";
const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnFocus: false,
  pauseOnHover: false,
  autoplay: true,
  autoplaySpeed: 8000,
};

// markup
const TochiPage = ({ location }) => {
  return (
    <Layout>
      {/* サイトタイトル */}
      <Meta title="土地募集" />

      <div
        className="slide"
        style={{
          fontSize: 0,
          padding: "0 !important",
        }}
      >
        <Slider {...sliderSettings}>
          <div className="videowrap">
            <video muted autoPlay playsInline>
              <track kind="captions" srcLang="ja" />
              <source src={VideoSrc} type="video/mp4" />
            </video>
          </div>
        </Slider>
      </div>

      <div style={{ backgroundColor: "#f0f3ef", zIndex: 10, position: "relative" }}>
        <div className="container">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <a className="breadcrumb__link" href="/">
                  ホーム
                </a>
                <span className="breadcrumb__separator" aria-hidden="true">
                  {" "}
                  &gt;{" "}
                </span>
              </li>
              <li className="breadcrumb__item">
                <a aria-current="page" className="breadcrumb__link" href="/tochi/">
                  土地募集
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <section className="sec position-relative bg-illust pt-0" style={{ backgroundColor: "#f0f3ef" }}>
        {/* <div className="illust illust--1">
          <StaticImage alt="illust" src="../images/illust_01.png" />
        </div>
        <div className="illust illust--2">
          <StaticImage alt="illust" src="../images/illust_02.png" />
        </div> */}
        <Container>
          <h2 className="font--xlarge font-weight-bold text-center mb-4">土地の管理でこんなお悩みございませんか？</h2>
          <Row>
            <Col md={4}>
              <StaticImage alt="illust" src="../images/img-tochi-1@2x.png" />
              <p className="text-center mb-0 mt-2 font-weight-bold">草刈などの管理</p>
            </Col>
            <Col md={4}>
              <StaticImage alt="illust" src="../images/img-tochi-2@2x.png" />
              <p className="text-center mb-0 mt-2 font-weight-bold">固定資産税などの負担</p>
            </Col>
            <Col md={4}>
              <StaticImage alt="illust" src="../images/img-tochi-3@2x.png" />
              <p className="text-center mb-0 mt-2 font-weight-bold">耕作できなくなった</p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec">
        <Container>
          <h2 className="ttl-h2 text-secondary font-weight-bold text-center mb-5">
            太陽光発電用地として
            <br />
            売却または貸して頂けないでしょうか？
          </h2>
          <h3 className="font--xlarge font-weight-bold text-center mb-md-4">こんな土地を探しています</h3>
          <Row>
            <Col md={6} className="mb-4">
              <StaticImage alt="illust" src="../images/search-1@2x.png" />
              <p className="mb-0 mt-2 small">約200枚の太陽光パネルを設置するため、土地の面積はおよそ250坪以上が必要になります。1000坪2000坪3000坪の土地も探しております。</p>
            </Col>
            <Col md={6} className="mb-4">
              <StaticImage alt="illust" src="../images/search-2@2x.png" />
              <p className="mb-0 mt-2 small">地目は基本的に問いません。雑種地だけでなく、山林、原野、宅地、雑種地、農地転用可能な農地まで。まずはご相談頂きましたら専門スタッフがお伺いします。</p>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-4">
              <StaticImage alt="illust" src="../images/search-3@2x.png" />
              <p className="mb-0 mt-2 small">太陽光で発電された電気を売電するには、既存の電気系統に連系する必要があります。近くに電柱がない場合もご相談くださいませ。</p>
            </Col>
            <Col md={6} className="mb-4">
              <StaticImage alt="illust" src="../images/search-4@2x.png" />
              <p className="mb-0 mt-2 small">土地の造成や、太陽光パネルなどの機器運搬が必要となりますので、太陽光発電用地に道路が面していると便利です。</p>
            </Col>
          </Row>
          <div
            className="my-5"
            style={{
              padding: "2rem",
              backgroundColor: "#fff9ea",
            }}
          >
            <h3 className="font--large text-secondary text-center font-weight-bold">
              上記の条件を満たさなくても問題ございません。
              <br className="d-md-inline d-none" />
              完全無料でお調べしますので、お気軽にお問い合わせくださいませ!
            </h3>
            <p className="small text-secondary text-center mb-0 mt-3">※敷地内に建物がある土地（解体工事費が相当かかる建物）・斜面が急な土地（傾斜角15度/27％以上）は御対応できかねます。</p>
          </div>
          <h3 className="font--large text-secondary text-center font-weight-bold">
            平安コーポレーションでは、
            <br />
            このようなお悩みを解決できます！
            <br />
            一度ご相談ください！
          </h3>
          <div className="mt-5 text-center">
            <a className="btn btn-warning text-white" href="https://www.heian-corp.jp/contact/solar2/" target="_blank" rel="noreferrer">
              ご相談・お問い合わせ
            </a>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default TochiPage;
